import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RequestHandler } from "../util/APIRequestHandler";
import { FORGOT_PASSWORD } from "../config/APIConfigList";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { LoadingOutlined } from "@ant-design/icons";

const ForgotPassword = () => {
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const [userInfo, setUserInfo] = useState({ isLoggedIn: false });
  const [loadingRequest, setLoadingRequest] = useState(false);
  const onFinishFailed = (errorInfo) => {};
  const onFinish = (values) => {
    setLoadingRequest(true);
    RequestHandler({
      ...FORGOT_PASSWORD,
      body: values,
    })
      .then((result) => {
        setLoadingRequest(false);
        userInfo.success = result.success;
        userInfo.msg = result.msg;
        setUserInfo({ ...userInfo });
        if (result.success) {
          setTimeout(() => (window.location = "/signin"), 3000);
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: 950 }}>
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ paddingLeft: 10, paddingRight: 10, width: "55%" }}
          >
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt="Neature" /> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1
                style={{ fontSize: 27, textAlign: "center", marginBottom: 0 }}
              >
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p
                style={{
                  fontSize: 14,
                  padding: 20,
                  marginBottom: 0,
                }}
              >
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* The platform gives financial institutions around the world
                    the flexibility to perform cross-border transactions
                    seamlessly and affordably. */}
                    <span style={{ fontWeight: "500" }}>Payments Hub: </span>
                    Empowers global financial institutions for seamless
                    cross-border transactions.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* It provides its users an easy-to-use interface so they can
                    monitor the end-to-end processing of their transactions. */}
                    <span style={{ fontWeight: "500" }}>
                      User-friendly Interface:{" "}
                    </span>
                    Easily monitor end-to-end transaction processing.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* By offering a message format converter that converts
                    messages from ISO15022 to ISO20022 Standards and vice versa,
                    it extends support for banks and service bureaus. */}
                    <span style={{ fontWeight: "500" }}>
                      Message Transformer:{" "}
                    </span>
                    Converts ISO15022 to ISO20022 Standards and vice versa,
                    aiding banks and service bureaus.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* To get started, sign in with your registered Email ID and
                    Password. */}
                    <span style={{ fontWeight: "500" }}>Get Started:</span> Sign
                    in with your registered Email ID and Password.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div
            className="gx-app-login-content"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              width: "45%",
              alignSelf: "center",
            }}
          >
            <div className="gx-app-logo" style={{ textAlign: "center" }}>
              <img
                alt="example"
                src="/assets/images/mci_hdr_color_logo.png"
                style={{ height: 50, marginTop: "-11%" }}
              />
            </div>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              style={{ marginTop: 45 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "The input is not valid E-mail!",
                  },
                ]}
                name="userName"
              >
                <Input placeholder="Email / User ID" />
              </Form.Item>

              {userInfo.success ? (
                <p style={{ color: "green" }}>{userInfo.msg}</p>
              ) : (
                <p style={{ color: "red" }}>{userInfo.msg}</p>
              )}

              <Form.Item style={{ textAlign: "center" }}>
                <div
                  style={{
                    marginBottom: 15,
                    textAlign: "end",
                    color: "#06094e",
                    fontWeight: "bold",
                    fontSize: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.location = "/signin";
                  }}
                >
                  Signin
                </div>
                <Button
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                  style={{ minWidth: 100 }}
                >
                  Submit
                  {loadingRequest && (
                    <LoadingOutlined style={{ fontSize: "16px" }} />
                  )}
                </Button>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
