import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { Country_List_Get } from './config/APIConfigList'
import { RequestHandler } from './util/APIRequestHandler'
export const GlobalContext = createContext()
export const useGlobalDetails = () => {
  return useContext(GlobalContext)
}
export const GlobalContextProvider = (props) => {
  const [countryNameWithCodeList, setCountryNameWithCodeList] = useState([])
  const [
    countryNameWithCurrencyList,
    setCountryNameWithCurrencyList,
  ] = useState([])
  useEffect(() => {
    GET_COUNTRY_LIST()
  }, [])
  const GET_COUNTRY_LIST = () => {
    RequestHandler({
      ...Country_List_Get,
    }).then((result) => {
      if (result.success) {
        let tempCountryNameWithCodeList = []
        let tempCountryNameWithCurrencyList = []
        result.data
          .sort((a, b) => a?.countryName?.localeCompare(b?.countryName))
          .map((data) => {
            let obj = {
              title: data?.countryName?.trim(),
              value: data?.iso3?.trim(),
            }
            tempCountryNameWithCodeList.push(obj)
            tempCountryNameWithCurrencyList.push({
              ...obj,
              currency: data?.currency,
              phoneCode: `+` + data?.phoneCode
            })
            return null
          })
        setCountryNameWithCodeList([...tempCountryNameWithCodeList])
        setCountryNameWithCurrencyList([...tempCountryNameWithCurrencyList])
      } else {
        setCountryNameWithCodeList([])
        setCountryNameWithCurrencyList([])
      }
    })
  }
  let value = {
    countryNameWithCodeList,
    countryNameWithCurrencyList,
  }
  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  )
}
