import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RequestHandler } from "../util/APIRequestHandler";
import { LOGIN, LOGIN_VERIFICATION } from "../config/APIConfigList";

import {
  hideAuthLoader,
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { SESSION_STORAGE_ITEM } from "../config/StaticProperties";
import AESEncyption from "../util/AESEncryption";

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  const [userInfo, setUserInfo] = useState({ isLoggedIn: false });

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    // let pwd =  AESEncyption(values?.password)
    // console.log('values', pwd)

    if (userInfo.isLoggedIn) {
      verifyToken(values);
    } else {
      dispatch(showAuthLoader());
      RequestHandler({
        ...LOGIN,
        body: {
          ...values,
          password: AESEncyption(values?.password),
        },
      }).then((result) => {
        // console.log('api/login result: ', result)
        userInfo.success = result.success;
        userInfo.msg = result.msg;
        setUserInfo({ ...userInfo, ...values });

        if (result.success) {
          // set token in local storage
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.USER_TOKEN,
            result.data["User-Token"]
          );
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.REFRESH_TOKEN,
            result.data["Refresh-Token"]
          );
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.ACCESS_TOKEN,
            result.data["Access-Token"]
          );
          sessionStorage.setItem(SESSION_STORAGE_ITEM.USER_ID, "1111");
          sessionStorage.setItem(SESSION_STORAGE_ITEM.USER_ID, "1111");

          // NotificationManager.success(result.msg);
          userInfo.isLoggedIn = true;
          setUserInfo({ ...userInfo, ...values });
          // console.log('userInfo: ', userInfo)

          // Uncomment these below two lines if you want to skip token validation
          dispatch(userSignInSuccess("1111"));
        }
        dispatch(hideAuthLoader());
      });
    }
  };

  const verifyToken = (data) => {
    // verify Token
    RequestHandler({
      ...LOGIN_VERIFICATION,
      body: {
        ...data,
        ...userInfo,
      },
    })
      .then((result) => {
        // console.log('verify/login result: ', result)

        userInfo.success = result.success;
        userInfo.msg = result.msg;
        setUserInfo({ ...userInfo });

        if (result.success) {
          dispatch(showAuthLoader());
          // dispatch(userSignIn({ ...userInfo, email: userInfo.email }));
          dispatch(userSignInSuccess("1111"));
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: 950 }}>
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ paddingLeft: 10, paddingRight: 10, width: "55%" }}
          >
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt="Neature" /> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1
                style={{ fontSize: 27, textAlign: "center", marginBottom: 0 }}
              >
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p
                style={{
                  fontSize: 15,
                  padding: 20,
                  marginBottom: 0,
                }}
              >
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* The platform gives financial institutions around the world
                    the flexibility to perform cross-border transactions
                    seamlessly and affordably. */}
                    <span style={{ fontWeight: "500" }}>Payments Hub: </span>
                    Empowers global financial institutions for seamless
                    cross-border transactions.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* It provides its users an easy-to-use interface so they can
                    monitor the end-to-end processing of their transactions. */}
                    <span style={{ fontWeight: "500" }}>
                      User-friendly Interface:{" "}
                    </span>
                    Easily monitor end-to-end transaction processing.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* By offering a message format converter that converts
                    messages from ISO15022 to ISO20022 Standards and vice versa,
                    it extends support for banks and service bureaus. */}
                    <span style={{ fontWeight: "500" }}>
                      Message Transformer:{" "}
                    </span>
                    Converts ISO15022 to ISO20022 Standards and vice versa,
                    aiding banks and service bureaus.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* To get started, sign in with your registered Email ID and
                    Password. */}
                    <span style={{ fontWeight: "500" }}>Get Started:</span> Sign
                    in with your registered Email ID and Password.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div
            className="gx-app-login-content"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              width: "45%",
              alignSelf: "center",
            }}
          >
            <div className="gx-app-logo" style={{ textAlign: "center" }}>
              <img
                alt="example"
                src="/assets/images/mci_hdr_color_logo.png"
                style={{ height: 50, marginTop: "-11%" }}
              />
            </div>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              style={{ marginTop: 45 }}
            >
              {!userInfo.isLoggedIn && (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                  name="username"
                >
                  <Input placeholder="Email" />
                </Form.Item>
              )}
              {!userInfo.isLoggedIn && (
                <Form.Item
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
              )}
              {userInfo.isLoggedIn && (
                <Form.Item
                  initialValue=""
                  rules={[
                    { required: true, message: "Please input your SMS OTP" },
                  ]}
                  name="smsOtp"
                >
                  <Input type="text" placeholder="SMS OTP" />
                </Form.Item>
              )}

              {userInfo.isLoggedIn && (
                <Form.Item
                  initialValue=""
                  rules={[
                    { required: true, message: "Please input your Email OTP" },
                  ]}
                  name="mailOtp"
                >
                  <Input type="text" placeholder="Email OTP" />
                </Form.Item>
              )}

              {/* <Form.Item>
                <Checkbox>
                  <IntlMessages id="appModule.iAccept" />
                </Checkbox>
                <span className="gx-signup-form-forgot gx-link">
                  <IntlMessages id="appModule.termAndCondition" />
                </span>
              </Form.Item> */}

              {userInfo.success ? (
                <p style={{ color: "green" }}>{userInfo.msg}</p>
              ) : (
                <p style={{ color: "red" }}>{userInfo.msg}</p>
              )}

              {!userInfo.isLoggedIn ? (
                <Form.Item style={{ textAlign: "center" }}>
                  {/* unlock account */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginBottom: 15,
                        textAlign: "end",
                        color: "#06094e",
                        fontWeight: "bold",
                        fontSize: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location = "/unlockaccount";
                      }}
                    >
                      Unlock Account
                    </div>
                    <div
                      style={{
                        marginBottom: 15,
                        textAlign: "end",
                        color: "#06094e",
                        fontWeight: "bold",
                        fontSize: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location = "/forgotpassword";
                      }}
                    >
                      Forgot Password?
                    </div>
                  </div>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    style={{ minWidth: 100 }}
                  >
                    Sign In
                  </Button>

                  {/* <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{' '}
                  <Link to="/signup">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Link> */}
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    style={{ minWidth: 100 }}
                  >
                    <IntlMessages id="app.userAuth.verifyToken" />
                  </Button>
                </Form.Item>
              )}

              {/*<div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <FacebookOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <GithubOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <TwitterOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}
                    />
                  </li>
                </ul>
              </div>
              <span className="gx-text-light gx-fs-sm">
                {" "}
                demo user email: 'demo@example.com' and password: 'demo#123'
              </span> */}
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
