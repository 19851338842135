import {
  API_METHOD,
  DEFAULT_AUTH_BASE_URL,
  REQUEST_TYPE,
} from '../../../config/APIConfigList'
import { RequestHandler } from '../../../util/APIRequestHandler'

export const DEFAULT_PROFILE_IMG = '/assets/images/img_avatar.png'
export const IMAGE_NOT_AVAILABLE = '/assets/images/image_not_available.png'
export const DEFAULT_USER_INFO = {
  username: '',
  email: '',
  profileURL: DEFAULT_PROFILE_IMG,
}
export const PROFILE_IMG_URL = async (profileURL) => {
  if (profileURL !== undefined && profileURL !== null) {
    profileURL = (
      DEFAULT_AUTH_BASE_URL.replace('api/v1/', '') + profileURL
    ).replace('api/v1/party/', 'api/v1/')
    profileURL = await RequestHandler({
      url: profileURL,
      method: API_METHOD.GET,
      requestType: REQUEST_TYPE.FILEDOWNLOAD,
    }).then(async (result) => {
      if (result.status) {
        const blob = await result.blob()
        const newBlob = new Blob([blob],{ type: "image/webp" })
        const blobUrl = window.URL.createObjectURL(newBlob)
        return blobUrl
      } else {
        return DEFAULT_PROFILE_IMG
      }
    })
  } else {
    profileURL = DEFAULT_PROFILE_IMG
  }

  return profileURL
}
export const COMPANY_IMG_URL = async (logo) => {
  if (logo !== undefined && logo !== null) {
    logo = (DEFAULT_AUTH_BASE_URL.replace('api/v1/', '') + logo).replace(
      'api/v1/',
      'api/v1/',
    )
    logo = await RequestHandler({
      url: logo,
      method: API_METHOD.GET,
      requestType: REQUEST_TYPE.FILEDOWNLOAD,
    }).then(async (result) => {
      if (result.status) {
        const blob = await result.blob()
        const newBlob = new Blob([blob],{ type: "image/webp" })
        const blobUrl = window.URL.createObjectURL(newBlob)
        return blobUrl
      } else {
        return IMAGE_NOT_AVAILABLE
      }
    })
  } else {
    logo = IMAGE_NOT_AVAILABLE
  }

  return logo
}
