import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import CustomScrollbars from 'util/CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '../../constants/ThemeSetting'
import IntlMessages from '../../util/IntlMessages'
import { useSelector } from 'react-redux'
import { GENERATE_MENU_LIST } from './SidebarConst'
const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup
const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings)
  const pathname = useSelector(({ common }) => common.pathname)
  const { rolePermission } = useSelector(({ rolePermission }) => rolePermission)
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications'
    }
    return ''
  }
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup'
    }
    return ''
  }
  const selectedKeys = pathname.substr(1)
  const defaultOpenKeys = selectedKeys.split('/')[1]

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {rolePermission &&
              rolePermission?.length > 0 &&
              GENERATE_MENU_LIST(rolePermission).map(
                (mainMenu) =>
                  mainMenu?._isMainMenu &&
                  mainMenu?.enableMainMenu && (
                    <SubMenu
                      key={mainMenu?.menuId}
                      popupClassName={getNavStyleSubMenuClass(navStyle)}
                      title={
                        <span>
                          {mainMenu?.icon}
                          <span>
                            <IntlMessages id={mainMenu?.title} />
                          </span>
                        </span>
                      }
                    >
                      {mainMenu.subMenu && mainMenu.subMenu.length > 0 && (
                        <>
                          {mainMenu.subMenu.map(
                            (subMenu) =>
                              !subMenu?._isMainMenu &&
                              subMenu?.mainMenuId &&
                              subMenu?.mainMenuId === mainMenu?.menuId && (
                                <Menu.Item key={subMenu?.link?.replace("/","")} style={{ marginTop: (subMenu?.title?.length > 30 && window.innerWidth < 1320 )? 5 : -2}} >
                                  <Link to={subMenu?.link}>
                                    {subMenu?.icon}
                                    <span style={{ 
                                overflowWrap:"break-word",
                                whiteSpace: "initial",
                                lineHeight: -40,
                                      }}>
                                      <IntlMessages id={subMenu?.title} />
                                    </span>
                                  </Link>
                                </Menu.Item>
                              ),
                          )}
                        </>
                      )}
                    </SubMenu>
                  ),
              )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  )
}

export default React.memo(SidebarContent)
