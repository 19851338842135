import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RequestHandler } from "../util/APIRequestHandler";
import {
  FORGOT_PASSWORD,
  SEND_OTP,
  UNLOCK_ACCOUNT,
} from "../config/APIConfigList";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

const UnlockAccount = () => {
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const [userInfo, setUserInfo] = useState({ isLoggedIn: false });
  const [showLockbtn, setShowLockbtn] = useState(true);
  const [email, setEmail] = useState();
  const [otp, setOTP] = useState();

  // Server call
  const sendOTP = () => {
    // console.log(`DEBUG_E`, email);
    RequestHandler({
      ...SEND_OTP,
      body: { userName: email },
    }).then((result) => {
      if (result.success) {
        setShowLockbtn(false);
      }
    });
  };

  const unlockAccount = () => {
    // console.log(`DEBUG_O`, otp);
    RequestHandler({
      ...UNLOCK_ACCOUNT,
      body: { username: email, mailOtp: otp },
    }).then((result) => {
      userInfo.success = result.success;
      userInfo.msg = result.msg;
      if (result.success) {
        window.location = "/signin";
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: 950 }}>
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ paddingLeft: 10, paddingRight: 10, width: "55%" }}
          >
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt="Neature" /> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1
                style={{ fontSize: 27, textAlign: "center", marginBottom: 0 }}
              >
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p
                style={{
                  fontSize: 14,
                  padding: 20,
                  marginBottom: 0,
                }}
              >
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* The platform gives financial institutions around the world
                    the flexibility to perform cross-border transactions
                    seamlessly and affordably. */}
                    <span style={{ fontWeight: "500" }}>Payments Hub: </span>
                    Empowers global financial institutions for seamless
                    cross-border transactions.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* It provides its users an easy-to-use interface so they can
                    monitor the end-to-end processing of their transactions. */}
                    <span style={{ fontWeight: "500" }}>
                      User-friendly Interface:{" "}
                    </span>
                    Easily monitor end-to-end transaction processing.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* By offering a message format converter that converts
                    messages from ISO15022 to ISO20022 Standards and vice versa,
                    it extends support for banks and service bureaus. */}
                    <span style={{ fontWeight: "500" }}>
                      Message Transformer:{" "}
                    </span>
                    Converts ISO15022 to ISO20022 Standards and vice versa,
                    aiding banks and service bureaus.
                  </li>
                  <li style={{ textAlign: "justify", marginBottom: 7 }}>
                    {/* To get started, sign in with your registered Email ID and
                    Password. */}
                    <span style={{ fontWeight: "500" }}>Get Started:</span> Sign
                    in with your registered Email ID and Password.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div
            className="gx-app-login-content"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              width: "45%",
              alignSelf: "center",
              marginTop: 10,
            }}
          >
            <div className="gx-app-logo" style={{ textAlign: "center" }}>
              <img
                alt="example"
                src="/assets/images/mci_hdr_color_logo.png"
                style={{ height: 50, marginTop: "-11%" }}
              />
            </div>
            <Form
              //   initialValues={{ remember: true }}
              name="basic"
              //   onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              style={{ marginTop: 45 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "The input is not valid E-mail!",
                  },
                ]}
                name="userName"
              >
                <Input
                  placeholder="Email"
                  onChange={({ target }) => setEmail(target?.value)}
                />
              </Form.Item>

              {userInfo.success ? (
                <p style={{ color: "green" }}>{userInfo.msg}</p>
              ) : (
                <p style={{ color: "red" }}>{userInfo.msg}</p>
              )}

              {showLockbtn && (
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    style={{ minWidth: 100 }}
                    onClick={sendOTP}
                  >
                    Unlock
                  </Button>
                </Form.Item>
              )}

              {!showLockbtn && (
                <>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "The input is not valid OTP!",
                      },
                      {
                        max: 6,
                        message: "Please enter 6 digit OTP",
                      },
                      {
                        min: 6,
                        message: "OTP is only 6 digits",
                      },
                    ]}
                    name=""
                  >
                    <Input
                      placeholder="OTP"
                      onChange={({ target }) => setOTP(target?.value)}
                    />
                  </Form.Item>
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                      style={{ minWidth: 100 }}
                      onClick={unlockAccount}
                    >
                      Verify OTP
                    </Button>
                  </Form.Item>
                  {userInfo.success ? (
                    <p style={{ color: "green" }}>{userInfo.msg}</p>
                  ) : (
                    <p style={{ color: "red" }}>{userInfo.msg}</p>
                  )}
                  <p
                    style={{
                      color: "#038fde",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >{`If your email is associated with Payments Hub, an OTP will be sent to your email`}</p>
                </>
              )}
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {/* {showMessage ? message.error(alertMessage.toString()) : null} */}
        </div>
      </div>
    </div>
  );
};

export default UnlockAccount;
