import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import 'assets/vendors/style'
import configureStore, { history } from './appRedux/store'
import './firebase/firebase'
import App from './containers/App/index'
import { GlobalContextProvider } from './GlobalContext'

const store = configureStore(/* provide initial state if any */)

const NextApp = (props) => (
  <GlobalContextProvider {...props}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </GlobalContextProvider>
)
export default NextApp
