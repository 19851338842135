import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URLSearchParams from 'url-search-params'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'

import AppLocale from 'lngProvider'
import MainApp from './MainApp'
import SignIn from '../SignIn'
import SignUp from '../SignUp'
import { setInitUrl } from 'appRedux/actions/Auth'
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from 'appRedux/actions/Setting'

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from '../../constants/ThemeSetting'
import ResetPasswordByToken from '../../routes/customViews/userAuth/ResetPasswordByToken'
import {
  CHECK_PERMISSION_BY_URL,
  MENULIST,
  ROLE_PERMISSIONS,
} from '../Sidebar/SidebarConst'
import { SESSION_STORAGE_ITEM } from '../../config/StaticProperties'
import ForgotPassword from '../ForgotPassword'
import UnlockAccount from '../UnlockAccount'

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(true)
  const [permissionForPage, setPermissionForPage] = React.useState(null)
  const { rolePermission } = useSelector(({ rolePermission }) => rolePermission)
  React.useEffect(() => {
    let token = sessionStorage.getItem(SESSION_STORAGE_ITEM.USER_TOKEN)
    if (token === undefined || token === null) {
      setLoading(true)
      setPermissionForPage(null)
      if (location?.pathname !== '/' && location?.pathname !== '/signin') {
        window.location = '/'
      }

      return
    }
    if (
      rolePermission === undefined ||
      rolePermission === null ||
      rolePermission.length === 0
    ) {
      ROLE_PERMISSIONS(dispatch)
    } else {
      if (
        location?.pathname !== '/main/accessdenied' &&
        location?.pathname !== '/' &&
        location?.pathname !== '/main/profile'
      ) {
        locationPermissionCheck()
      } else {
        setPermissionForPage(true)
        setLoading(false)
      }
    }
  }, [rolePermission, authUser])
  React.useEffect(() => {
    if (
      location?.pathname !== '/main/accessdenied' &&
      location?.pathname !== '/' &&
      location?.pathname !== '/main/profile' &&
      rolePermission &&
      rolePermission.length > 0
    ) {
      locationPermissionCheck()
    }
  }, [location])
  const locationPermissionCheck = () => {
    CHECK_PERMISSION_BY_URL(rolePermission, location?.pathname).then(
      (permission) => {
        if (
          !permission &&
          loading &&
          location?.pathname !== '/main/accessdenied'
        ) {
          window.location = '/main/accessdenied'
        } else {
          setPermissionForPage(true)
          setLoading(false)
        }
      },
    )
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        loading && permissionForPage === null ? (
          <></>
        ) : !loading && permissionForPage && authUser && authUser !== '' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout')
    document.body.classList.remove('framed-layout')
    document.body.classList.add('full-layout')
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout')
    document.body.classList.remove('framed-layout')
    document.body.classList.add('boxed-layout')
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout')
    document.body.classList.remove('full-layout')
    document.body.classList.add('framed-layout')
  }
}

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add('full-scroll')
    document.body.classList.add('horizontal-layout')
  } else {
    document.body.classList.remove('full-scroll')
    document.body.classList.remove('horizontal-layout')
  }
}

let styleSheetLink = document.createElement('link')
styleSheetLink.type = 'text/css'
styleSheetLink.rel = 'stylesheet'
document.body.appendChild(styleSheetLink)

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale)
  const navStyle = useSelector(({ settings }) => settings.navStyle)
  const layoutType = useSelector(({ settings }) => settings.layoutType)
  const themeColor = useSelector(({ settings }) => settings.themeColor)
  const themeType = useSelector(({ settings }) => settings.themeType)
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL)

  const dispatch = useDispatch()
  const { authUser, initURL } = useSelector(({ auth }) => auth)

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl')
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl')
      document.documentElement.setAttribute('data-direction', 'ltr')
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`
    }
  }, [themeColor, isDirectionRTL])

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme')
      styleSheetLink.href = '/css/dark_theme.min.css'
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme')
      styleSheetLink.href = ''
    }
  }, [themeType])

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale
  }, [locale])

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname))
    }
    const params = new URLSearchParams(location.search)

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')))
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')))
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')))
    }
  }, [location.search, dispatch, initURL, location.pathname])

  useEffect(() => {
    setLayoutType(layoutType)
    setNavStyle(navStyle)
  }, [layoutType, navStyle])

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin')
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        handleAfterLoginPage()
      } else {
        history.push(initURL)
      }
    }
  }, [authUser, initURL, location, history])
  const handleAfterLoginPage = async () => {
    let permissionDetails = await ROLE_PERMISSIONS(dispatch)
    if (permissionDetails?.permission?.length > 0) {
      let defaultPage =
        permissionDetails?.permission[0].roleDto?.defaultScreen || null
      if (
        defaultPage !== undefined &&
        defaultPage !== null &&
        defaultPage !== ''
      ) {
        let redirectUrl = '/main/accessdenied'
        let defaultPageVerfied = permissionDetails?.permission?.findIndex((h)=> h?.screen?.pageCode?.trim() === defaultPage?.trim())
        if(defaultPageVerfied != -1){
          MENULIST.map((data) => {
            if (data.menuId.trim() === defaultPage.trim()) {
              redirectUrl = data.link
            }
            return null;
          })
        }
        history.push(redirectUrl)
      } else {
        history.push('/main/dashboard/dashboard')
      }
    } else {
      history.push('/main/accessdenied')
    }
  }
  const currentAppLocale = AppLocale[locale.locale]

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? 'rtl' : 'ltr'}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/unlockaccount" component={UnlockAccount} />
          <Route
            exact
            path="/personal-info/userName/:userName/token/:tok"
            component={ResetPasswordByToken}
          />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)
