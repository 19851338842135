const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  userInfo: null,
  rolePermission: sessionStorage.getItem('role_permission') || [],
}
export const ROLE_PERMISSIONS_INIT_STATE = INIT_STATE
const RolePermissionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_ROLE_PERMISSION': {
      return {
        ...state,
        loader: false,
        rolePermission: action.payload,
      }
    }
    case 'USER_DETAILS': {
      return {
        ...state,
        userInfo: action.payload,
      }
    }
    case 'SIGNOUT_USER_SUCCESS': {
      window.location = '/'
      return {
        ...state,
        ...INIT_STATE,
      }
    }

    default:
      return state
  }
}

export default RolePermissionReducer
