import CryptoJS, { AES } from "crypto-js";
const iv = "yourivare1234567";
const key = "356d9abc7532ceb0945b615a622c3370";

const fkey = CryptoJS.enc.Utf8.parse(key);
const fiv = CryptoJS.enc.Utf8.parse(iv);

const AESEncyption =  (password) => {
  try {
    const encrypted = AES.encrypt(password, fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted
  } catch (error) {
    console.error("Encryption failed:", error);
  }
};

export default AESEncyption;
