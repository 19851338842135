export const ADDRESS_TYPE = {
  COMPANY_ADDRESS: 'COMPANYADDRESS',
  CORRESPONDENCE_ADDRESS: 'CORRESPONDENCEADDRESS',
  CUSTOMER_ADDRESS: 'CUSTOMERADDRESS',
  RESIDENTIAL_ADDRESS: 'RESIDENTIALADDRESS',
}
export const KYC_KYB_STATUS = [
  { title: "Pending", value: "RCMP"},
  { title: "Initiated", value: "RCMI"},
  { title: "Approved", value: "RCAP"},
  { title: "Rejected", value: "RCRJ"},
]
export const IDENTITY_TYPE_LIST = [
  {
    title: 'Goverment ID',
    value: 'GOVTID',
  },
  {
    title: 'Driving Licence',
    value: 'DL',
  },
  {
    title: 'Passport',
    value: 'PASSPORT',
  },
  {
    title: 'Address Proof',
    value: 'ADP',
  },
  {
    title: 'Identity Proof',
    value: 'IDP',
  },
  {
    title: 'Tax Documents',
    value: 'TXD',
  },
  {
    title: 'Business Registration Documents',
    value: 'BRD',
  },
  {
    title: 'Employement Verification Documents',
    value: 'EVD',
  },
  {
    title: 'FinancialDocuments',
    value: 'FID',
  },
  {
    title: 'Business Nature Document',
    value: 'BND',
  },
  {
    title: 'Other',
    value: 'OTHER',
  },
]
export const ENTITY_TYPE = {
  FI: 'FI',
  ORG: 'ORG',
  CUSTOMER: 'CUSTOMER',
  PRIMARY_CONTACT: 'PRIMARYCONTACT',
  BENEFICIARY: 'BENEFICIARY',
  PROVIDER: 'PROVIDER',
}
export const IDENTITY_TYPE = {
  DL: 'DL',
  PL: 'PL',
}
export const SUPPORT_DOMAIN = ['.com', '.net', '.org', '.co', '.in']
export const TRANSFER_PROTOCOL = ['http://', 'https://']
export const ROLE_TYPE = {
  ADMIN: 'ADMIN',
}
export const FORM_VIEW_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  APPROVE: 'APPROVE',
  DELETE: 'DELETE',
}
export const TABLE_DATA_ALIGN = {
  DEFAULT: 'left',
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}
export const SESSION_STORAGE_ITEM = {
  USER_NAME: 'user_name',
  USER_EMAIL: 'user_email',
  USER_ID: 'user_id',
  USER_TOKEN: 'user_token',
  REFRESH_TOKEN: 'refresh_Token',
  ACCESS_TOKEN: 'access_Token',
  COUNTRY_LIST: 'country_list',
  USER_ROLE: 'user_role',
  PARTY_ID:'party_id'
}

export const BACKGROUND_WEB_WORKER = {
  SUMMARY_TABLE_FILTER: 'SUMMARYTABLEFILTER',
}
export const TABLE_PAGE_SIZE_LIST = [5, 10, 20, 50, 100, 250]
export const DEFAULT_TABLE_PAGE_SIZE = 20
export const DEFAULT_CARD_PAGE_SIZE = 10
