export const API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};
export const REQUEST_TYPE = {
  NORMAL: "NORMAL",
  MULTIPART: "MULTIPART",
  FILEDOWNLOAD: "FILEDOWNLOAD",
  BLOBDOWNLOAD: "BLOBDOWNLOAD",
};
export const DEFAULT_AUTH_BASE_URL =
  process.env.REACT_APP_DEV_DEFAULT_AUTH_BASE_URL ||
  process.env.REACT_APP_DEFAULT_AUTH_BASE_URL;
export const DEFAULT_TRANS_BASE_URL =
  process.env.REACT_APP_DEV_DEFAULT_TRANS_BASE_URL ||
  process.env.REACT_APP_DEFAULT_TRANS_BASE_URL;
export const DEFAULT_CONVERTER_BASE_URL =
  process.env.REACT_APP_DEV_DEFAULT_CONVERTER_BASE_URL ||
  process.env.REACT_APP_DEFAULT_CONVERTER_BASE_URL;
export const DEFAULT_COMPLIANCE_BASE_URL =
  process.env.REACT_APP_DEV_DEFAULT_COMPLIANCE_BASE_URL ||
  process.env.REACT_APP_DEFAULT_COMPLIANCE_BASE_URL;
export const DEFAULT_COMPLIANCE_TRANS_BASE_URL =
  process.env.REACT_APP_DEV_DEFAULT_COMPLIANCE_TRANS_BASE_URL ||
  process.env.REACT_APP_DEFAULT_COMPLIANCE_TRANS_BASE_URL;
export const DEFAULT_REPORT_BASE_URL =
  process.env.REACT_APP_DEV_REPORT_BASE_URL ||
  process.env.REACT_APP_DEFAULT_REPORT_BASE_URL;

// ============================ LOGIN APIS ============================
export const LOGIN = {
  url: DEFAULT_AUTH_BASE_URL + "login",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const LOGOUT = {
  url: DEFAULT_AUTH_BASE_URL + "logout",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const LOGIN_VERIFICATION = {
  url: DEFAULT_AUTH_BASE_URL + "login/verification",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FORGOT_PASSWORD = {
  url: DEFAULT_AUTH_BASE_URL + "forgotpassword",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
// UNLOCK ACCOUNT ///////////////////////////
export const SEND_OTP = {
  url: DEFAULT_AUTH_BASE_URL + "unlock/user",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const UNLOCK_ACCOUNT = {
  url: DEFAULT_AUTH_BASE_URL + "unlock/verify",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
// //////////////////////////////////

export const GET_ROLE_PERMISSIONS = {
  url: DEFAULT_AUTH_BASE_URL + "onSuccessfulLogin",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

export const Reset_Password_By_Token = {
  url: DEFAULT_AUTH_BASE_URL + "user/setpassword",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};

// /////////////////////////////// DASHBOARD /////////////
export const Dashboard_data = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/userwidgetpermissions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const WidgetAPIData = {
  url: "",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

//Start of FI PRPFILE LIST ///
export const FI_PROFILE_LIST = {
  url: DEFAULT_AUTH_BASE_URL + "party/profilelist",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_FI_PROFILE_STATUS = {
  url: DEFAULT_AUTH_BASE_URL + "status/FI_ORG",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}

//END of FI PRPFILE LIST ///

/*Start Of  FI & ORG*/
export const FI_List = {
  url: DEFAULT_AUTH_BASE_URL + "party/list",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_CompanyData_Add = {
  url: DEFAULT_AUTH_BASE_URL + "party/ficreation",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ORG_CompanyData_Add = {
  url: DEFAULT_AUTH_BASE_URL + "party/companydata",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_CompanyData_Add_Update = {
  url: DEFAULT_AUTH_BASE_URL + "party/ficreation",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ORG_CompanyData_Add_Update = {
  url: DEFAULT_AUTH_BASE_URL + "party/companydata",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_CompanyData_GET = {
  url: DEFAULT_AUTH_BASE_URL + "party/companydata/", //{partyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_CompanyData_Address_Add = {
  url: DEFAULT_AUTH_BASE_URL + "party/addresses",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_CompanyData_Address_Update = {
  url: DEFAULT_AUTH_BASE_URL + "party/addresses",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_NEW_ADDRESS_ADD = {
  url: DEFAULT_AUTH_BASE_URL + "party/companyaddresses",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
}
export const FI_NEW_ADDRESS_UPDATE = {
  url: DEFAULT_AUTH_BASE_URL + "party/companyaddresses",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
}
export const FI_Licence_Add = {
  url: DEFAULT_AUTH_BASE_URL + "party/upload",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const FI_Licence_Update = {
  url: DEFAULT_AUTH_BASE_URL + "party/upload",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const FI_Licence_GET = {
  url: DEFAULT_AUTH_BASE_URL + "party/docs/", //{partyId}/{entity}/{type} --
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
// FI MODULE
export const FI_Module_GET = {
  url: DEFAULT_AUTH_BASE_URL + "partymodule/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Module_Save = {
  url: DEFAULT_AUTH_BASE_URL + "partymodule/save",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Module_Update = {
  url: DEFAULT_AUTH_BASE_URL + "partymodule/update",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const IDENTITY_TYPE_LIST = {
  url: DEFAULT_AUTH_BASE_URL + "party/identity/types",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
// API TYPE LIST
export const API_TYPE_LIST = {
  url: DEFAULT_AUTH_BASE_URL + "url/types",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
// AUTH TYPE LIST
export const GET_AUTH_TYPE_LIST = {
  url: DEFAULT_AUTH_BASE_URL + "url/auth/types",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
// FI IDENTITY DELETE
export const FI_Licence_DELETE = {
  url: DEFAULT_AUTH_BASE_URL + "party/doc/delete/",
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_API_URL_DELETE = {
  url: DEFAULT_AUTH_BASE_URL + "url/delete/",
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};

export const FI_Primary_Contact_PI_Add = {
  //Personal Information
  url: DEFAULT_AUTH_BASE_URL + "party/primarycontact/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Primary_Contact_PI_Update = {
  //Personal Information
  url: DEFAULT_AUTH_BASE_URL + "party/primarycontact/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Primary_Contact_PA_Add = {
  //Personal Address
  url: DEFAULT_AUTH_BASE_URL + "party/addresses",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Primary_Contact_PA_Update = {
  //Personal Address
  url: DEFAULT_AUTH_BASE_URL + "party/addresses",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Primary_Contact_ID_Add = {
  //Personal Driving License Information
  url: DEFAULT_AUTH_BASE_URL + "party/primarycontact/addidentity",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const FI_Primary_Contact_ID_Update = {
  //Personal Driving License Information
  url: DEFAULT_AUTH_BASE_URL + "party/primarycontact/updateidentity",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const FI_Primary_Contact_GET = {
  //Personal Driving License Information
  url: DEFAULT_AUTH_BASE_URL + "party/primarycontact/", //{partyId} --
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Register = {
  url: DEFAULT_AUTH_BASE_URL + "party/register",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_Register_GET = {
  url: DEFAULT_AUTH_BASE_URL + "user/admin/", //{partyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_URL_ADD = {
  url: DEFAULT_AUTH_BASE_URL + "url/add/", //{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FI_URL_GET = {
  url: DEFAULT_AUTH_BASE_URL + "url/party/", //{partyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of  FI  & ORG*/


/*Start Of  Service Provider*/
export const Service_Provider_List = {
  url: DEFAULT_AUTH_BASE_URL + "services/allserviceproviders", //{parentPartyId} changed to login
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SP_Info_Add = {
  url: DEFAULT_AUTH_BASE_URL + "services/provider/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SP_Info_Update = {
  url: DEFAULT_AUTH_BASE_URL + "services/provider/update",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SP_Identity_Add = {
  url: DEFAULT_AUTH_BASE_URL + "services/provider/addidentity",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const SP_Identity_Update = {
  url: DEFAULT_AUTH_BASE_URL + "services/provider/updateidentity",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const SP_Identity_Get = {
  url: DEFAULT_AUTH_BASE_URL + "services/provider/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of  Service Provider*/
/*Start Of  Customer*/
export const Customer_Profile_List = {
  url: DEFAULT_AUTH_BASE_URL + "customer/profilelist",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_List = {
  url: DEFAULT_AUTH_BASE_URL + "customer/findall", //{parentPartyId} changed to login
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
  body: { active: true },
};
export const Customer_Personal_Info_Add = {
  url: DEFAULT_AUTH_BASE_URL + "customer/personaldetails/create",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_Personal_Info_Update = {
  url: DEFAULT_AUTH_BASE_URL + "customer/personaldetails/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_Address_Add = {
  url: DEFAULT_AUTH_BASE_URL + "customer/residentialAddress/create",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_Address_Update = {
  url: DEFAULT_AUTH_BASE_URL + "customer/residentialAddress/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_Identity_Add = {
  url: DEFAULT_AUTH_BASE_URL + "customer/document/create",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const Customer_Identity_Update = {
  url: DEFAULT_AUTH_BASE_URL + "customer/document/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const Customer_Get = {
  url: DEFAULT_AUTH_BASE_URL + "customer/find/", //{customerId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of  Customer*/
// CUSTOMER DETAILS SCREEN //
export const Customer_Details_Get = {
  url: DEFAULT_AUTH_BASE_URL + "customer/personalprofile/", // {customerId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_Widget_Benificiary = {
  url: DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/beneficiary/", // {customerId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_Widget_PaymentMode = {
  url: DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/paymentmode/", // {customerId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
// END OF CUSTOMER DETAILS SCREEN //
/*Start Of  Services*/
export const PartyServices_List = {
  url: DEFAULT_AUTH_BASE_URL + "services/all",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_List_Filtered = {
  url: DEFAULT_AUTH_BASE_URL + "services/all",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_Provider_List = {
  url: DEFAULT_AUTH_BASE_URL + "services/allserviceproviders",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_Service_List = {
  url: DEFAULT_AUTH_BASE_URL + "services/allservicetype",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_Service_List_BY_FI = {
  url: DEFAULT_AUTH_BASE_URL + "services/allservicetype",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_Add = {
  url: DEFAULT_AUTH_BASE_URL + "services/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_GET = {
  url: DEFAULT_AUTH_BASE_URL + "services/", //{partyServiceId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_Update = {
  url: DEFAULT_AUTH_BASE_URL + "services/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServiceCharge_Update = {
  url: DEFAULT_AUTH_BASE_URL + "services/charges/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PartyServices_Delete = {
  url: DEFAULT_AUTH_BASE_URL + "services/delete/", //{}
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of  Services*/

/*Strat Of Paring*/
export const Paring_List = {
  url: DEFAULT_AUTH_BASE_URL + "pairing/findall?", //partyId=${userID}&status=NEW
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Paring_Add = {
  url: DEFAULT_AUTH_BASE_URL + "pairing/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of  Paring*/

/*Strat Of Single Payment*/
export const FX_Rate = {
  url: DEFAULT_TRANS_BASE_URL + "fxrate/rate",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Customer_KYC_Approved = {
  url: DEFAULT_AUTH_BASE_URL + "customer/fetchallby",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Initite_Single_Payment = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/single",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Initite_Single_Payment_Charges = {
  url: DEFAULT_TRANS_BASE_URL + "trans/charges",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PurposeOfPayment = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/purposes/list",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of  Paring*/
/*Start Of  Payment Histroy*/
export const Payment_History = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/all",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
// PAYMENT STATUS SCREEN
export const Payment_Status = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/all",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Payment_Details = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/details/", //{transactionId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PAYMENT_STATUS_CUSTOMER_FILTER = {
  url: DEFAULT_REPORT_BASE_URL + "reportconfiguration/customer/fetchall",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL
}
export const PAYMENT_STATUS_BENEFICIARY_FILTER = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/fetchall/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL
}
export const PAYMENT_METHOD_LIST = {
  url: DEFAULT_REPORT_BASE_URL + "reportconfiguration/paymentmethods",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL
}
/*End Of  Payment Histroy*/

/*Start Of  KYB*/
export const KYB_Status_Count = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/party/statuscount",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Eligible_Summary_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/party/status?status=RCMP",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Common_Summary_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/party/screening/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Initiate_Summary_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/party/initiated/all",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Initiate_Score_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/", //{screeningId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Form_Details = {
  url: DEFAULT_AUTH_BASE_URL + "party/", //{partyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Initiate = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/initiate/party",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Approve = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/rem/party",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYB_Reject = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/rej/party",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of KYB*/

/*Start Of  KYC*/
export const KYC_Status_Count = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/person/statuscount",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Eligible_Summary_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/person/status?status=RCMP",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Common_Summary_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/person/screening/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Initiate_Summary_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/person/initiated/all",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Form_Details = {
  url: DEFAULT_AUTH_BASE_URL + "party/person/", //{id}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Initiate_Score_Details = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/", //{screeningId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Initiate = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/initiate/person",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const KYC_Approval = {
  url: DEFAULT_COMPLIANCE_BASE_URL + "compliance/rem/person",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of KYC*/

/*Start Of  Beneficiary*/
export const Beneficiary_List_By_Customer = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/fetchallby/", //{Customer ID}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Summary_Details = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/fetchall/", //{Teller ID}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Summary_List_Details = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/fetchalllistview/", //{Teller ID}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Summary_List_Details_With_Filter = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/fetchalllistview",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Add = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Update = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_GET = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/", //{id}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Delete = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/delete",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Verify = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/confirm",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Card_Delete = {
  url: DEFAULT_TRANS_BASE_URL + "card/delete/",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Beneficiary_Bank_Delete = {
  url: DEFAULT_TRANS_BASE_URL + "bankaccnt/delete/",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of Beneficiary*/

/*Start Of  Account Setup*/
export const Account_List = {
  url: DEFAULT_TRANS_BASE_URL + "customer/getcustbeneflist",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Card_Setup = {
  url: DEFAULT_TRANS_BASE_URL + "card/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const BankAccount_Setup = {
  url: DEFAULT_TRANS_BASE_URL + "bankaccnt/reg",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Card_Details_Get = {
  url: DEFAULT_TRANS_BASE_URL + "card/", //{Customer ID}/benef/{Beneficiry ID}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Bank_Details_Get = {
  url: DEFAULT_TRANS_BASE_URL + "bankaccnt/", //{Customer ID}/benef/{Beneficiry ID}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

export const Get_Account_Details = {
  url: DEFAULT_TRANS_BASE_URL + "customer/getdisbursement/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Get_Disbursment_List = {
  url: DEFAULT_TRANS_BASE_URL + "customer/getdisbursementtype/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Get_Card_Details = {
  url: DEFAULT_TRANS_BASE_URL + "card/", // cardid
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Get_Bank_Details = {
  url: DEFAULT_TRANS_BASE_URL + "bankaccnt/", // bankid,
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

/*End Of  Account Setup*/

/*Start Of Payment mode Setup*/
export const Get_Customer_PM = {
  url: DEFAULT_TRANS_BASE_URL + "customer/getdisbursement/CUSTOMER/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const BenificiaryList_By_Customer = {
  url: DEFAULT_TRANS_BASE_URL + "benfcary/fetchall/", //{Customer ID}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Get_Benificiary_PM = {
  url: DEFAULT_TRANS_BASE_URL + "customer/getdisbursement/BENEFICIARY/",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

/*Start Of Limit Approval*/
export const Limit_Approval_List = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/limitapprovals",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Limit_Approval = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/limitapproval/approveorreject",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of Limit Approval*/

/*Start Of Compliance Approval*/
export const Compliance_Approval_List = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/complianceapprovals",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Compliance_Approval = {
  url:
    DEFAULT_TRANS_BASE_URL + "transaction/complianceapproval/approveorreject",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
/*End Of Compliance Approval*/
// START TRANSACTION COMPLIANCE
export const TransactionCompliance_list = {
  url: DEFAULT_COMPLIANCE_TRANS_BASE_URL + "aml/transaction/details",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const Payment_Approval_List = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/mpTransactions",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Payment_Approval = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/debitconfirm/bulkapproveorreject",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const Disbursement_Approval_List = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/creditconfirm",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Disbursement_Approval = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/creditconfirm/approveorreject",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================Maintanance File Upload API ===========================
export const TRANSACTIONS_FILE_UPLOAD = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/upload",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const TRANSACTIONS_FILE_LIST = {
  url: DEFAULT_AUTH_BASE_URL + `uploadhistory/TRANSACTION`,
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
//============================ Converter File Upload API ============================

export const CONVERTER_FILE_UPLOAD = {
  url: DEFAULT_CONVERTER_BASE_URL + "messageconverter/uploadfile",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};

export const CONVERTER_FILE_UPLOAD_HISTORY = {
  url: DEFAULT_CONVERTER_BASE_URL + "data/upload/history",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================ IOSetup API ============================
export const IO_List = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/findAll/FileIOConfiguration/", //{parentPartyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const IO_Add = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/insert/FileIOConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FIELDS = {
  url: DEFAULT_CONVERTER_BASE_URL + "definition/find/duplicationcheck/keywords",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const IO_Update = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/update/FileIOConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const IO_Get = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/get/FileIOConfiguration", //{partyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const IO_Drop = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/delete/FileIOConfiguration/", //{partyId}
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================ DeliveryInstructionConfiguration API ============================
export const DELIVERY_List = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/findAll/DeliveryInstructionConfiguration", //{parentPartyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const DELIVERY_Add = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/insert/DeliveryInstructionConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const DELIVERY_Update = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/update/DeliveryInstructionConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const DELIVERY_Get = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/get/DeliveryInstructionConfiguration", //{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const DELIVERY_Drop = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/delete/DeliveryInstructionConfiguration", //{partyId}
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};
export const DELIVERY_Email_keywords = {
  url: DEFAULT_CONVERTER_BASE_URL + "definition/getNotificationValueOptions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const RULES_FOR_CONDITION = {
  url: DEFAULT_CONVERTER_BASE_URL + "definition/getRuleOptions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROUTE_TO = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/findAll/FileIOConfiguration/",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROUTE_TO_DUPLICATEFILE = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/find/duplicationcheck/receivers",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const CONNECTIVITY_LIST = {
  url: DEFAULT_CONVERTER_BASE_URL + "definition/moduleList",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SENDER_RECEIVER_CONVERTER = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/findAll/PartyConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SENDER_RECEIVER_COMPLIANCE = {
  url: DEFAULT_AUTH_BASE_URL + "party/list/FI",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const MESSAGE_TYPE_CONVERTER = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "definition/findAll/MessageTypeAndCodeDefinition",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const MESSAGE_TYPE_COMPLIACNE = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "definition/findAll/MessageTypeAndCodeDefinition",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const TRUNCATION_TEMPLATE_KEYWORDS = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "definition/find/unique/ISOMappingDefinition",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================================= Processing ===========================

export const PROCESSING_List_Input = {
  url: DEFAULT_CONVERTER_BASE_URL + "data/findAll/input", //{parentPartyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const PROCESSING_List_Output = {
  url: DEFAULT_CONVERTER_BASE_URL + "data/findAll/output", //{parentPartyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const PROCESSING_GetTraffic = {
  url: DEFAULT_CONVERTER_BASE_URL + "data/traffic", //{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const PROCESSING_Get_File_Content = {
  url: DEFAULT_CONVERTER_BASE_URL + "data/filecontent", //{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const RE_INITATE_ERROR_RECORD = {
  url: DEFAULT_CONVERTER_BASE_URL + "utils/reinitiate/single/output/file/delivery/", //{dbId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}
export const RE_INITATE_ALL_ERROR_RECORD = {
  url: DEFAULT_CONVERTER_BASE_URL + "utils/reinitiate/all/output/file/delivery", 
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}
export const RE_INITATE_ALL_ERROR_RECORD_INPUT = {
  url: DEFAULT_CONVERTER_BASE_URL + "utils/reinitiate/all/input/file/processing", 
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}

export const RE_INITATE_ERROR_RECORD_INPUT = {
  url: DEFAULT_CONVERTER_BASE_URL + "utils/reinitiate/single/input/file/processing/", //{dbId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}
//============================ Party Configuration API ============================
export const PARTY_CONFIG_List = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/findAll/PartyConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PARTY_CONFIG_DELETE = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/delete/PartyConfiguration/", //{dbId}
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PARTY_CONFIG_Add = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/insert/PartyConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const PARTY_CONFIG_Update = {
  url: DEFAULT_CONVERTER_BASE_URL + "maintenance/update/PartyConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
//============================ Routing Code Configuration API ============================
export const RCC_List = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/findAll/RoutingCodeConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const RCC_DELETE = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/delete/RoutingCodeConfiguration/", //{dbId}
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};
export const RCC_Add = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/insert/RoutingCodeConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const RCC_Update = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/update/RoutingCodeConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================ Service Routing Configuration API ============================
export const SRC_List = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/findAll/ServiceRoutingConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SRC_Definition = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "definition/find/unique/ServiceRoutingDefinition/",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SRC_Add = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/insert/ServiceRoutingConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SRC_Update = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/update/ServiceRoutingConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SRC_Delete = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "maintenance/delete/ServiceRoutingConfiguration",
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};
//============================ ROLESetup API ============================

export const SCREENS_List = {
  url: DEFAULT_AUTH_BASE_URL + "security/screens/", //{parentPartyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROLE_List = {
  url: DEFAULT_AUTH_BASE_URL + "security/roles/", //{parentPartyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROLE_Add = {
  url: DEFAULT_AUTH_BASE_URL + "security/rolepermission/save",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROLE_Update = {
  url: DEFAULT_AUTH_BASE_URL + "security/rolepermission/save",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROLE_Get = {
  url: DEFAULT_AUTH_BASE_URL + "security/permissions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ROLE_Drop = {
  url: DEFAULT_AUTH_BASE_URL + "security/rolepermission",
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};

//==========================Start Of  User Management ==========================
export const User_List = {
  url: DEFAULT_AUTH_BASE_URL + "user/list",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const User_Add = {
  url: DEFAULT_AUTH_BASE_URL + "user/add",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const User_Update = {
  url: DEFAULT_AUTH_BASE_URL + "user/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const User_Get = {
  url: DEFAULT_AUTH_BASE_URL + "user/", //{partyId}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const User_Drop = {
  url: DEFAULT_AUTH_BASE_URL + "user/drop/", //{partyId}
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Active_User_Roles = {
  url: DEFAULT_AUTH_BASE_URL + "security/active/roles",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}
/*End Of  Teller*/
// ========================== WIDGET DASHBOARD =============================
export const Widgets_Party = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/widgets",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Widget_Role_Save = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/rolewidgetpermissions/save",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Widget_Role_Update = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/rolewidgetpermissions/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Widget_Role_Permission = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/rolewidgetpermissions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================ FI CONFIGURATION API ============================

export const getAllConfiguration = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/findAllConfigurationsByParty",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const workFlowGetDefinition = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/getAll/workflowdefinition",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

export const workFlowAdd = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/add/workflowconfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const workFlowUpdate = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/update/workflowconfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const workFlowDelete = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/delete/workflowconfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const feeNameListGet = {
  url:
    DEFAULT_AUTH_BASE_URL + "transactionconfig/getAll/feesandchargestypelist",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

export const feesAndChargesListAdd = {
  url:
    DEFAULT_AUTH_BASE_URL + "transactionconfig/add/feesandchargesconfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const feesAndChargesListUpdate = {
  url:
    DEFAULT_AUTH_BASE_URL +
    "transactionconfig/update/feesandchargesconfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const feesAndChargesListDelete = {
  url:
    DEFAULT_AUTH_BASE_URL +
    "transactionconfig/delete/feesandchargesconfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const limitApprovalAdd = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/add/limit",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const limitApprovalListGet = {
  url: DEFAULT_AUTH_BASE_URL + "transactionconfig/getall",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

export const Message_List_Get = {
  url:
    DEFAULT_CONVERTER_BASE_URL +
    "definition/findAll/MessageTypeAndCodeDefinition",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Country_List_Get = {
  url: DEFAULT_AUTH_BASE_URL + "country/list",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const Enable_Auto_Approve = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/limitapproval/auto",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FeeAndChargesList = {
  url: DEFAULT_AUTH_BASE_URL + "feesandcharges",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const FeeAndChargesSave = {
  url: DEFAULT_AUTH_BASE_URL + "feesandcharges",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
//============================ Start of NEW CONNECTIVITY SETUP ============================//
export const FilePatternList = {
  url: DEFAULT_CONVERTER_BASE_URL + "definition/getFileNamePatternOptions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};

//============================ Start of ISO Translation Configuration API ============================
export const ISO_Config_Summary_List = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/findAll/ISOMappingConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ISO_Mapping_Definition_List = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "definition/find/unique/ISOMappingDefinition",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ISO_Mapping_Definition_Insert = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/insert/ISOMappingConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ISO_Mapping_Definition_Update = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/update/ISOMappingConfiguration",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ISO_Mapping_Definition_Delete = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "maintenance/delete/ISOMappingConfiguration/", //{dbId}
  method: API_METHOD.DELETE,
  requestType: REQUEST_TYPE.NORMAL,
};
export const ISO_Swift_Keywords = {
  url:
    DEFAULT_CONVERTER_BASE_URL + "definition/getSwiftEnvelopeTemplateOptions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
//============================ End of ISO Translation Configuration API ============================
export const PROFILE_IMAGE_UPLOAD = {
  url: DEFAULT_AUTH_BASE_URL + "user/upload/potrait",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const PARTY_LOGO_UPLOAD = {
  url: DEFAULT_AUTH_BASE_URL + "party/upload/logo",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.MULTIPART,
};
export const RESET_PASSWORD_PROFILE_PAGE = {
  url: DEFAULT_AUTH_BASE_URL + "resetpassword",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
// ==============================USER WIDGET SELECTION ============================
export const USER_WIDGET_PERMISSION = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/userwidgetpermissions",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const USER_WIDGET_SAVE = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/userwidgetpermissions/save",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const USER_WIDGET_UPDATE = {
  url: DEFAULT_AUTH_BASE_URL + "dashboard/userwidgetpermissions/update",
  method: API_METHOD.PUT,
  requestType: REQUEST_TYPE.NORMAL,
};

// ============================= REPORT API'S ======================/////
export const GET_REPORT_LIST = {
  url: DEFAULT_REPORT_BASE_URL + "reportconfiguration/reportlist",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_REPORT_FILTERS = {
  url: DEFAULT_REPORT_BASE_URL + "reportconfiguration/inputparameters", // reportId
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const POST_REPORT_FILTERS = {
  url: DEFAULT_REPORT_BASE_URL + "report/common",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const REPORT_DOWNLOAD = {
  url: DEFAULT_REPORT_BASE_URL + "report/download",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.BLOBDOWNLOAD,
};
export const REPORT_WIDGET_DATA = {
  url: DEFAULT_REPORT_BASE_URL + "widget/common",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
// ======================END OF REPORT API ===============///
//=======================START OF RT PAYMENT INITIATION ========//
export const GET_RTP_CUSTOMER_LIST = {
  url: DEFAULT_AUTH_BASE_URL + "customer/fetchAll",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_RTP_BENEFICIARY_LIST = {
  url: DEFAULT_REPORT_BASE_URL + "beneficiary/fetchall/", // customer ID
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_RTP_DISBURSEMET_LIST = {
  url: DEFAULT_TRANS_BASE_URL + "customer/getdisbursement/", //  {type}/${ID}/${method}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_RTP_TRANSACTION_FEES = {
  url: DEFAULT_TRANS_BASE_URL + "trans/charges",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_RTP_PURPOSE_OF_PAYMENT = {
  url: DEFAULT_TRANS_BASE_URL + "transaction/purposes/list",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const SEND_RTP_TRANSACTION = {
  url: DEFAULT_TRANS_BASE_URL + "rtptransaction/single",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_RTP_STATUS_LIST = {
  url: DEFAULT_AUTH_BASE_URL + "status/rtp",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
}
//=======================END OF RT PAYMENT INITIATION ========//
//=======================START OF RT PAYMENT LISTING SCREEN ========//
export const GET_RT_PAYMENT_LIST = {
  url: DEFAULT_TRANS_BASE_URL + "rtptransaction/all",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

//=======================END OF RT PAYMENT LISTING SCREEN ========//

// =========================FI PROFILE SCREEN ===== //
export const GET_FI_DETAILS = {
  url: DEFAULT_AUTH_BASE_URL + "party/profile", // {party id}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_FI_DETAILS_PROFILE = {
  url: DEFAULT_AUTH_BASE_URL + "party/profile",
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_CUSTOMER_WIDGET_DETAILS = {
  url: DEFAULT_AUTH_BASE_URL + "customer/widgetdatabyparty", //{party id}
  method: API_METHOD.GET,
  requestType: REQUEST_TYPE.NORMAL,
};
export const GET_TRANSACTION_STATUS_WIDGET_DETAILS = {
  url: DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/transactionstatus",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const GET_TRANSACTION_CURRENCY_WIDGET_DETAILS = {
  url: DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/currency",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const GET_TRANSACTION_PAYMENTMODE_WIDGET_DETAILS = {
  url: DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/paymentmode",
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL,
};

export const GET_CREDIT_AMOUNT ={
  url:  DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/credited", // /{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL
}

export const GET_DEBIT_AMOUNT ={
  url:  DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/debited", // /{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL
}

export const GET_PAYABLE_AMOUNT ={
  url:  DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/debited/pending", // /{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL
}
export const GET_RECEIVABLE_AMOUNT ={
  url:  DEFAULT_REPORT_BASE_URL + "transaction/widgetdata/credit/pending", // /{partyId}
  method: API_METHOD.POST,
  requestType: REQUEST_TYPE.NORMAL
}