import React from "react";
import { Button, Form, Input } from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import "./ResetPassword.css";
import { NotificationManager } from "react-notifications";
import { RequestHandler } from "../../../../util/APIRequestHandler";
import { Reset_Password_By_Token } from "../../../../config/APIConfigList";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "../../../../components/CircularProgress";
import { hideAuthLoader, showAuthLoader } from "../../../../appRedux/actions";
import AESEncyption from "../../../../util/AESEncryption";
const ResetPasswordByToken = (props) => {
  const dispatch = useDispatch();
  const { loader } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const [error, setError] = React.useState("");
  const onFinish = () => {
    dispatch(showAuthLoader());
    setError("");
    let reqBody = {
      userName: props.match.params.userName,
      tok: props.match.params.tok,
    };
    if (
      form.getFieldValue("password") &&
      form.getFieldValue("confirmpassword") &&
      form.getFieldValue("password") === form.getFieldValue("confirmpassword")
    ) {
      reqBody["password"] = AESEncyption(form.getFieldValue("password"));
      RequestHandler({
        ...Reset_Password_By_Token,
        body: reqBody,
      }).then((result) => {
        if (result.success) {
          NotificationManager.success("Password reset successfully");
          window.location = "/signin";
        } else {
          setError(result.msg);
        }
        dispatch(hideAuthLoader());
      });
    } else {
      dispatch(hideAuthLoader());
      setError("Password & Confirm Password should match");
    }
  };
  return (
    <div className="gx-app-login-wrap" id="resetPasswordByToken">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content" style={{ width: "100%" }}>
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid" style={{ alignSelf: "center" }}>
              <div style={{ textAlign: "center", fontSize: 35 }}>
                <FaLock />
              </div>
              <h3 style={{ color: "#fff" }}>
                You have successfully verified the email. Please set the
                password.
              </h3>
            </div>
          </div>
          <div
            className="gx-app-login-content"
            style={{ width: "100%", padding: "20px 35px 20px" }}
          >
            <Form
              wrapperCol={{
                span: 24,
                offset: 1,
              }}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
              form={form}
            >
              <Form.Item
                labelCol={{ span: 24 }}
                label="Password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  {
                    pattern: new RegExp(/^(?=.*\d).{8,}$/),
                    message: "Use 8 characters or more for your password"
                  },
                  {
                    pattern: new RegExp(
                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
                    ),
                    message: `At least one special character is required`,
                  },
                  {
                    pattern: new RegExp(/[A-Z]/),
                    message: "At least one uppercase alphabet is required",
                  },
                  {
                    pattern: new RegExp(/[a-z]/),
                    message: "At least one lowercase alphabet is required",
                  },
                  {
                    pattern: new RegExp(/[0-9]/),
                    message: "At least one number is required",
                  }
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Confirm Password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
                name="confirmpassword"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              {error && error !== "" ? (
                <p style={{ color: "red", marginTop: 10, marginLeft: 15 }}>
                  {error}
                </p>
              ) : (
                <></>
              )}
              <Form.Item
                labelCol={{ span: 24 }}
                style={{ textAlign: "center", marginTop: 15 }}
              >
                <Button
                  type="primary"
                  className="gx-mb-0"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  <IntlMessages id="btn_submit" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordByToken;
