import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'
import { NotificationContainer } from 'react-notifications'
const Main = ({ match }) => (
  <>
    <Switch>
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import('./dashboard'))}
      />
      <Route
        path={`${match.url}/maintenance`}
        component={asyncComponent(() => import('./maintenance'))}
      />
      <Route
        path={`${match.url}/compliance`}
        component={asyncComponent(() => import('./Compliance'))}
      />
      <Route
        path={`${match.url}/converter`}
        component={asyncComponent(() => import('./Converter'))}
      />
      <Route
        path={`${match.url}/payment`}
        component={asyncComponent(() => import('./Payment'))}
      />
      <Route
        path={`${match.url}/configuration`}
        component={asyncComponent(() => import('./Configuration'))}
      />
      <Route
        path={`${match.url}/security`}
        component={asyncComponent(() => import('./Security'))}
      />
      <Route
        path={`${match.url}/widgets`}
        component={asyncComponent(() => import('./Widgets'))}
      />
      <Route
        path={`${match.url}/metrics`}
        component={asyncComponent(() => import('./Metrics'))}
      />
      <Route
        path={`${match.url}/layouts`}
        component={asyncComponent(() => import('./Layouts'))}
      />
      <Route
        path={`${match.url}/accessdenied`}
        component={asyncComponent(() => import('./AccessDenied'))}
      />
      <Route
        path={`${match.url}/algolia`}
        component={asyncComponent(() => import('../algolia'))}
      />
      <Route
        path={`${match.url}/profile`}
        component={asyncComponent(() => import('./Profile'))}
      />
      <Route
        path={`${match.url}/reports`}
        component={asyncComponent(() => import('./Report'))}
      />
      <Route
        path={`${match.url}/rpayments`}
        component={asyncComponent(() => import('./RPayments'))}
      />
    </Switch>
    <NotificationContainer />
  </>
)

export default Main
